body{
    min-width: $min-body-width;
    &.nav-shown{
        overflow: hidden;
    }
}
.nk{
    &-body{
        outline: none;
    }
    &-app{
        &-root{
            outline: none;
        }
    }
    &-main{
        position: relative;
    } 
    &-wrap{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    &-content{
        padding: $content-gap-y 4px;
				margin-top: 55px;
    		position: relative;
        &-fs{
            min-height: calc(100vh - (#{$header-height} + #{$header-height}));
            display: flex;
            align-items: center;
        }
    }
}

@include media-breakpoint-up(sm){
    .nk{
        &-content{
            padding: ($content-gap-y + 8) $content-gap-x-sm;
            &-fluid{
                padding-left: 22px;
                padding-right: 22px;
            }
        }
    }
}
@include media-breakpoint-up(lg){
    .nk{
        &-content{
            &-lg {
                padding-top: ($content-gap-y * 1.5);
                padding-bottom: ($content-gap-y * 1.5);
            }
            &-fluid{
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}
@media (min-width: 1660px){
    .nk-content{
        &-lg {
            padding-top: ($content-gap-y * 2.25);
            padding-bottom: ($content-gap-y * 2.25);
        }
        &-fluid{
            padding-left: 44px;
            padding-right: 44px;
        }
    }
}

// Content
.nk-content-body{
    flex-grow: 1;
}
.nk-wrap-nosidebar{
    .nk-content{
        padding: 0 !important;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

.fm-head {
	display: flex;
	align-items: center;
	padding: 1.5rem;
	&:not(:last-child) {
		border-bottom: 1px solid #e5e9f2;
	}
}
.fm-content {
	padding: 1.5rem;
	&:not(:last-child) {
		border-bottom: 1px solid #e5e9f2;
	}
	.title {
		margin-bottom: .75rem;
	}
}
.fm-footer {
	padding: 1.5rem;
	&:not(:last-child) {
		border-bottom: 1px solid #e5e9f2;
	}
}
.fm-count {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 44px;
	width: 44px;
	font-size: 16px;
	border-radius: 50%;
	color: #526484;
	border: 2px solid #dbdfea;
	margin-right: 1rem;
	flex-shrink: 0;
}
.fm-title {
	.title {
		margin: .25rem 0;
	}
}

@media (min-width: 576px) {
	.fm-head {
		padding: 1.5rem 2.25rem;
	}
	.fm-content {
		padding: 1.5rem 2.25rem;
		padding-bottom: 1.75rem;
	}
	.fm-footer {
		padding: 1.5rem 2.25rem;
		padding: 2.25rem;
	}
}

.autocomplete-dropdown-container {
	background-color: #fff;
	box-shadow: 0 1px 3px 0 #e6ebf1;
	padding: 10px;
	position: absolute;
	width: 100%;
	z-index: 100;
	&:empty {
		display: none;
	}
}

.phone-code {
	min-width: 320px !important;
    width: auto !important;
}

.pointer-cursor{
    cursor: pointer;
}

.hidden-checkbox .ant-checkbox {
    display: none;
}

.pre-loader {
    position: fixed;
    top: 50%;
    width: 100%;
}

.inner-pre-loader{
    top: 50%;
    width: 100%;
    margin: 20% 0;
}

.react-datepicker__aria-live {
    display: none;
}

.form-error-text { 
    color: #ff4d4f;
}

.react-datepicker__header__dropdown {
    justify-content: space-evenly;
    display: flex;
    margin-top: 10px;
}

.receipt-head-overlay {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgb(0 0 0 / 5%);
}

.nk-top-products {
	.item {
		display: flex;
		align-items: center;
		padding: 1.625rem 0;
		line-height: 1.2;
		border-bottom: 1px solid #eee;
		&:last-child {
			border-bottom: none;
		}
		.info {
			width: 80%;
		}
	}
	.thumb {
		width: 44px;
		margin-right: 1rem;
		img {
			border-radius: 4px;
		}
	}
	.total {
		margin-left: auto;
		text-align: right;
	}
	.title {
		font-size: 0.9375rem;
		color: $base-color;
		margin-bottom: 0.25rem !important;
	}
	.amount {
		font-size: 0.9375rem;
		color: $base-color;
		margin-bottom: 0.25rem !important;
	}
	.price {
		font-size: 0.8125rem;
		color: $base-400;
		margin-bottom: 1px;
	}
	.count {
		font-size: 0.8125rem;
		color: $base-400;
		margin-bottom: 1px;
	}
}

.nk-iv-wg3-list {
	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .5rem 1.25rem;
		border-bottom: 1px solid #e5e9f2;
		.lead-text {
			font-size: 13px;
			font-weight: 400;
		}
	}
	&:last-child {
		padding: 0 0 1rem;
		li {
			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.ant-radio-inner {
  width: 20px;
  height: 20px;

  &::after {
    width: 12px;
    height: 12px;
  }
}

.timeline {
	color: $base-400;
	line-height: 1.3;

	& + .timeline,
	.timeline-list + &-head {
			margin-top: 1.75rem;
	}

	.time {
			color: $base-400;
			display: block;
			font-size: 12px;
	}
}

.timeline-head {
	color: $base-400;
	font-size: 14px;
	margin-bottom: 1rem;
}

.timeline-item {
	align-items: flex-start;
	display: flex;
	position: relative;

	&:not(:last-child) {
			padding-bottom: 1.5rem;

			&:before {
					background: #dbdfea;
					content: "";
					height: calc(100% - 11px);
					left: 5px;
					position: absolute;
					top: 13px;
					width: 1px;
			}
	}

	@media(max-width: 413px) {
			flex-wrap:wrap;
	}
}

.timeline-status {
	border-radius: 50%;
	flex-shrink: 0;
	height: 11px;
	margin-top: 2px;
	position: relative;
	width: 11px;

	&.is-outline::after {
			background: #fff;
			border-radius: 50%;
			content: "";
			height: 7px;
			left: 2px;
			position: absolute;
			top: 2px;
			width: 7px;
	}
}

.timeline-date {
	color: $base-400;
	flex-shrink: 0;
	line-height: 1rem;
	margin-left: .75rem;
	position: relative;
	width: 90px;

	@media(max-width: 413px) {
			width: 80px;
	}

	.icon {
			color: $base-400;
			display: inline-block;
			margin-right: .25rem;
			position: absolute;
			right: 0;
			top: 2px;
			vertical-align: middle;
	}
}

.timeline-data {
	padding-left: 8px;

	@media(max-width: 413px) {
			padding: .75rem 0 0 24px;
	}
}

.timeline-title {
	color: $base-color;
	font-size: 15px;
	margin-bottom: .75rem;
}

.timeline-des {
	color: $base-400;

	p {
			margin-bottom: .25rem;
	}
}

@media(min-width: 576px) {
	.timeline + .timeline,
	.timeline-list + .timeline-head {
			margin-top:2.5rem;
	}
}

.dropdown-menu ul li span {
	white-space: nowrap;
}

.ant-upload-list-item-thumbnail {
	display: grid;
}

.alignment-top {
	align-items: baseline !important;
}

.center-recaptcha {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ant-tag-close-icon {
	vertical-align: 0;
}

.upload-product-image {
  width: 150px;
  height: 8rem;
  overflow: hidden;

	img {
		object-fit: cover;
	}
}

.border-width-3 {
	border-width: 3px !important;
}

.remove-button {
	position: absolute;
	right: 10px;
	justify-content: flex-end;
	z-index: 1;
}

.subscription-alert-banner {
	top: 65px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	z-index: 999;
	margin: 0;
}

.btn-fab {
	position: fixed;
	bottom: 100px;
	right: 24px;
	z-index: 99;
	width: 60px;
	justify-content: center;
	height: 60px;
	font-size: 20px;
}